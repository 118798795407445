import {
  IconActiveTracking,
  IconStandardTracking,
} from "@shared/components/lib/index";
import theme from "../theme";
import { styled } from "@mui/material/styles";
import { useContext } from "react";
import { OrderContext } from "../context";
import { CART } from "../constants/statuses";
import { getTimelineColor } from "../helper/colors";

const ColoredStepIcon = styled("div")(() => ({
  zIndex: 1,
  color: "#fff",
  width: 56,
  height: 56,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
}));

export const ColorStepIcon = (label) => (props) => {
  const { order } = useContext(OrderContext);
  const timelineColor = getTimelineColor(order);
  const { active, completed } = props;
  const mainColor = timelineColor;
  const color = theme.palette.common.white;
  const strokeColor =
    active || completed ? timelineColor : theme.palette.primary.main;
  if (order.isDelivered && active) {
    return (
      <ColoredStepIcon>
        <IconActiveTracking color={mainColor} />
      </ColoredStepIcon>
    );
  }
  if (label === CART) {
    return (
      <ColoredStepIcon>
        <IconActiveTracking color={mainColor} />
      </ColoredStepIcon>
    );
  }
  return (
    <ColoredStepIcon>
      <IconStandardTracking color={color} strokeColor={strokeColor} />
    </ColoredStepIcon>
  );
};
