import { createTheme } from "@mui/material/styles";

const themePalette = createTheme({
  palette: {
    primary: {
      light: "#e9d7d7", //  LIghtest Grey
      main: "#B9B9BB", // light grey
      dark: "#79797C", // medium grey
    },
    secondary: {
      light: "#3F3D3D",
      main: "#222223", // dark grey
      dark: "#000000",
    },
    error: {
      light: "#FFF5F3",
      main: "#D10A0A",
    },
    success: {
      light: "#F3FEF9",
      main: "#1CA518",
    },
  },
});
const fontFamily = '"Baloo 2", "Roboto", "Arial", sans-serif';
const theme = createTheme(themePalette, {
  typography: {
    caption: {
      fontFamily,
      fontSize: 24,
      fontWeight: 500,
      lineHeight: 1,
    },
    body1: {
      fontFamily,
      fontSize: 17,
      lineHeight: 1,
    },
    body2: {
      fontFamily,
      fontSize: 13,
      lineHeight: 1,
    },
    h4: {
      fontFamily,
      fontSize: "32px",
      lineHeight: "36px",
      fontWeight: 600,
    },
    h5: {
      fontFamily,
      fontSize: "24px",
      lineHeight: "28px",
      fontWeight: 600,
    },
  },
  breakpoints: {
    values: {
      mobile: 375,
      tablet: 768,
      desktop: 1920,
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "48px",
          border: `2px solid ${themePalette.palette.primary.main}`,
          borderRadius: "3px",
          "& .MuiInputBase-input ": {
            color: themePalette.palette.secondary.main,
            fontSize: "17px",
          },
          "&&:hover": {
            borderColor: themePalette.palette.primary.dark,
          },
          "&.Mui-error": {
            borderColor: themePalette.palette.error.main,
            "&&:hover": {
              borderColor: themePalette.palette.error.main,
            },
          },
          "& input": {
            padding: "8px 12px",
          },
          "& fieldset": {
            visibility: "hidden",
          },
        },
        focus: {
          borderColor: themePalette.palette.primary.dark,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: 0,
          "& .MuiFormLabel-root": {
            fontSize: "13px",
            left: "2px",
            transform: "scale(1)",
            color: themePalette.palette.secondary.light,
          },
          "& .MuiFormLabel-root.Mui-focused": {
            color: themePalette.palette.secondary.light,
          },

          "& .MuiFormHelperText-root": {
            marginLeft: "2px",
            marginTop: 0,
            fontSize: "13px",
            color: themePalette.palette.secondary.light,
          },
          "& .MuiInputLabel-animated": {
            transition: "none !important",
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: "none !important",
          "&.MuiButton-outlined": {
            border: `2px solid ${themePalette.palette.secondary.dark}`,
            borderRadius: "2px",
            color: themePalette.palette.secondary.dark,
            fontSize: "17px",
            height: "48px",
            minWidth: "174px",

            "&&:hover": {
              border: `2px solid ${themePalette.palette.secondary.dark}`,
              borderRadius: "2px",
            },
            "&& .MuiTouchRipple-child": {
              backgroundColor: themePalette.palette.primary.main,
            },
            "&&.Mui-disabled": {
              border: `2px solid ${themePalette.palette.primary.dark}`,
              borderRadius: "2px",
              color: themePalette.palette.primary.dark,
            },
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          color: themePalette.palette.secondary.main,
        },
        standardError: {
          backgroundColor: themePalette.palette.error.light,
        },
        standardSuccess: {
          backgroundColor: themePalette.palette.success.light,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: "none",
        },
      },
    },
  },
});

export default theme;
