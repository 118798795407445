import React, { useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import OrderCard from "./OrderCard";
import TrackingOrderHead from "../components/TrackingOrderHead";
import theme from "../theme";
import Alert from "@mui/material/Alert";
import { OrderContext } from "../context";

const TrackingOrder = () => {
  const { error } = useContext(OrderContext);
  return (
    <Grid container justifyContent="center">
      {error && (
        <Grid container justifyContent="center">
          <Alert severity="error" icon={false}>
            {error}
          </Alert>
        </Grid>
      )}
      <Grid item sx={styles.root}>
        <Grid container flexDirection="column" alignItems="center">
          <TrackingOrderHead />
          <OrderCard />
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  root: {
    width: "78%",
    maxWidth: "1004px",
    [theme.breakpoints.between(
      theme.breakpoints.values.mobile,
      theme.breakpoints.values.tablet
    )]: {
      width: "92%",
    },
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      width: "100%",
    },
  },
};

export default TrackingOrder;
