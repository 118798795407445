import React from "react";
import { DateFormatter } from "@shared/components/lib/index";
import Typography from "@mui/material/Typography";
import theme from "../theme";

const DeliveryDateComponent = ({ order, textColor }) => {
  const deliveryDate = order.isDelivered
    ? order.deliveryDate
    : order.eta.etaStart;
  return (
    <Typography variant="h4" color={textColor} sx={styles.deliveryDate}>
      <DateFormatter date={deliveryDate} format="cccc dd MMMM" locale="nl" />{" "}
    </Typography>
  );
};

const styles = {
  deliveryDate: {
    textTransform: "capitalize",
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      fontSize: "20px",
    },
  },
};

export default DeliveryDateComponent;
