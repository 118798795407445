import { useContext } from "react";
import Typography from "@mui/material/Typography";
import {
  formatTimeETA,
  formatDateETA,
  formatTime,
} from "common-utils/utils/formatter";
import isEmpty from "lodash/isEmpty";
import { formatTimeFromSeconds } from "common-utils/utils/time";
import { OrderContext } from "../context";
import theme from "../theme";
import { getTextColor } from "../helper/colors";

export const DeliveryTimeInterval = () => {
  const { order } = useContext(OrderContext);
  const textColor = getTextColor(order);
  if (order.isDelivered) {
    return (
      <Typography color={textColor} sx={styles.time}>
        {formatTime(order.deliveryDate)}
      </Typography>
    );
  }
  if (order.eta && !isEmpty(order.eta)) {
    return (
      <Typography color={textColor} sx={styles.time}>
        Tussen&nbsp;
        {formatTimeETA(order.eta)}
      </Typography>
    );
  }
  return (
    <Typography color={textColor} sx={styles.time}>
      {order.product.type !== "express" && (
        <>
          Tussen&nbsp;
          {formatTimeFromSeconds(order.product.delivery.startSeconds)}
          &nbsp;-&nbsp;
          {formatTimeFromSeconds(order.product.delivery.endSeconds)}
        </>
      )}
    </Typography>
  );
};

const styles = {
  time: {
    fontSize: "20px",
    wordSpacing: "8px",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      fontSize: "15px",
    },
  },
};
