import React from "react";
import Typography from "@mui/material/Typography";
import theme from "../theme";
import dompurify from "dompurify";

export const getFAQItems = (faqData) => {
  if (!faqData || !faqData.length) {
    return [];
  }

  return faqData.map((faq, index) => {
    const sanitizer = dompurify.sanitize(faq.text);
    return {
      title: faq.title,
      text: faq.text,
      getContent: () => (
        <Typography sx={styles.text}>
          <span dangerouslySetInnerHTML={{ __html: sanitizer }} />
        </Typography>
      ),
    };
  });
};
const styles = {
  text: {
    fontSize: "17px",
    lineHeight: "22px",
    textIndent: 0,
    paddingRight: theme.spacing(3),
    color: theme.palette.secondary.main,
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      fontSize: "15px",
    },
  },
};
