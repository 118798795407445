import theme from "../theme";

export const getBackgroundColor = (order) =>
  order.branding ? order.branding.topBarColor : theme.palette.error.main;

export const getTextColor = (order) =>
  order.branding ? order.branding.secondaryColor : theme.palette.common.white;

export const getTimelineColor = (order) =>
  order.branding && order.branding.timelineColor
    ? order.branding.timelineColor
    : theme.palette.common.black;
