import React, { useContext } from "react";
import { OrderContext } from "../context";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import theme from "../theme";
import head from "lodash/head";

const Status = () => {
  const { order } = useContext(OrderContext);
  const latestStatus = head(order.statusHistory);

  if (!latestStatus) {
    return <></>;
  }

  return (
    <Grid container sx={styles.root}>
      <Grid container justifyContent="start">
        <Typography variant="body1" sx={styles.text}>
          Status:
        </Typography>
        &nbsp;
        <Typography
          variant="body1"
          color={theme.palette.error.main}
          sx={styles.text}
        >
          {order.statusName ? order.statusName : latestStatus.name}
        </Typography>
      </Grid>
      {latestStatus.reason && (
        <Grid container justifyContent="start">
          <Typography variant="body1" sx={styles.text}>
            Reason:
          </Typography>
          &nbsp;
          <Typography
            variant="body1"
            color={theme.palette.error.main}
            sx={styles.text}
          >
            {latestStatus.reason.name}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const styles = {
  root: {
    marginTop: theme.spacing(4),
  },
  text: {
    fontWeight: 600,
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      fontSize: "15px",
    },
  },
};

export default Status;
