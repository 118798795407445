import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

export const StyleMuiGrid = styled((props) => <Grid {...props} />)(
  ({ theme, sx }) => ({
    "&.MuiGrid-root": {
      ...sx,
    },
  })
);
