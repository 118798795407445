import React, { useContext } from "react";
import TrackingOrder from "./TrackingOrder";
import { Navigate, useParams } from "react-router-dom";
import { OrderContext } from "../context";

const TrackingPage = () => {
  const { tracking, postcode } = useParams();
  const { order } = useContext(OrderContext);

  return (
    <>
      {!order ? (
        <Navigate to={`/${tracking}/${postcode}`} replace />
      ) : (
        <TrackingOrder />
      )}
    </>
  );
};

export default TrackingPage;
