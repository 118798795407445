import React, { useContext, useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { ColorStepIcon } from "../components/ColorStepIcon";
import { Connector } from "../components/Connector";
import theme from "../theme";
import ExpectedDelivery from "../components/ExpectedDelivery";
import { extendStepsOnCart } from "../helper/stepperFormat";
import { OrderContext } from "../context";
import { CART, STANDARD_STEPS } from "../constants/statuses";
import EstimatedTime from "../components/EstimatedTime";
import Status from "../components/Status";
import MainAccordion from "../components/MainAccordion";
import OrderInfo from "../components/OrderInfo";
import OrderTrackingTimeline from "../components/OrderTrackingTimeline";
import InternalAccordion from "../components/InternalAccordion";
import { getFAQItems } from "../components/getFAQItems";
import { getMultiColiItems } from "../components/getMultiColiItems";
import { matchingStep } from "../helper/statusChecking";
import { getRequestUI } from "common-utils/utils/api";

const OrderCard = () => {
  const { order, referenceOrders, activeStep, setActiveStep } =
    useContext(OrderContext);

  useEffect(() => {
    const activeStep = matchingStep(order.step);
    setActiveStep(activeStep);
  }, []);

  const faqItems = getFAQItems(order.faq);
  const extendSteps = extendStepsOnCart(activeStep, STANDARD_STEPS);
  const isCart = extendSteps.some((step) => step === CART);
  return (
    <Card variant="outlined">
      <Grid container sx={styles.card} flexDirection="column">
        <ExpectedDelivery />
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Stepper
              sx={styles.stepper}
              alternativeLabel
              activeStep={isCart ? activeStep + 1 : activeStep}
              connector={<Connector />}
            >
              {extendSteps.map((label) => {
                const timelineStep = order.allSteps.find(
                  (s) => s.id === label.step
                );
                return (
                  <Step sx={styles.step} key={label.name}>
                    <StepLabel
                      sx={styles.stepLabel}
                      StepIconComponent={ColorStepIcon(label)}
                    >
                      {timelineStep ? timelineStep.name : ""}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
        </Grid>

        <Grid container flexDirection="column" sx={styles.bottomSide}>
          <EstimatedTime />
          {order.showStatusAndReason && <Status />}

          <MainAccordion
            items={[
              {
                id: "order_info",
                title: "Pakketgegevens",
                children: <OrderInfo />,
              },
              {
                id: "order_route",
                title: "Route van je pakket(ten)",
                children: !referenceOrders.length ? (
                  <OrderTrackingTimeline
                    order={order}
                    activeStep={activeStep}
                  />
                ) : (
                  <InternalAccordion items={getMultiColiItems()} />
                ),
              },
              {
                id: "FAQ",
                title: "Veelgestelde vragen",
                children: <InternalAccordion items={getFAQItems(faqItems)} />,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

const styles = {
  stepper: {
    width: "100%",
    maxWidth: 760,
    paddingLeft: 0,
    display: "flex",
    justifyContent: "center",
  },
  step: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: 0,
    },
  },
  stepLabel: {
    "& .MuiStepLabel-label": {
      [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
        fontSize: "11px",
      },
    },
  },
  card: {
    borderRadius: `10px`,
    boxShadow: "0px 4px 10px 5px rgba(205, 215, 245, 0.25)",
    maxWidth: 760,
    padding: "0 74px 56px 74px",
    width: "100%",
    minHeight: "544px",
    [theme.breakpoints.down("md")]: {
      padding: "0 32px 56px 32px",
    },
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      padding: 0,
    },
  },
  bottomSide: {
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      padding: "0 24px",
    },
  },
};

export default OrderCard;
