import React, { useContext } from "react";
import theme from "../theme";
import { OrderContext } from "../context";
import _ from "lodash";
import "../index.css";
import { Link } from "@mui/material";
import { getTextColor } from "../helper/colors";

const RedirectButton = () => {
  const { order } = useContext(OrderContext);
  const linkTextColor = getTextColor(order);

  const redirect = () => {
    window.location.replace(order.trackingLink.redirectTrackingLink);
  };

  const styles = {
    linkText: {
      color: linkTextColor,
      textDecoration: "underline",
      fontWeight: "bold",
      fontSize: "20px",
    },
  };

  return (
    <>
      {order.trackingLink.redirectTrackingLink && (
        <Link
          href={order.trackingLink.redirectTrackingLink}
          onClick={redirect}
          style={styles.linkText}
        >
          Klik om je pakket te volgen via de vervoerder
        </Link>
      )}
    </>
  );
};

export default RedirectButton;
