import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import TrackingApp from "./pages/TrackingApp";

class Main extends Component {
  render() {
    return (
      <Routes>
        <Route path="/:tracking?/:postcode?" element={<TrackingApp />} />
      </Routes>
    );
  }
}

export default Main;
