import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SVG from "react-inlinesvg";
import theme from "../theme";
import { OrderContext } from "../context";

const TrackingOrderHead = () => {
  const { order } = useContext(OrderContext);
  return (
    <Grid container alignItems="center" sx={styles.root}>
      <Grid container sx={styles.logo}>
        {order.branding && order.branding.svg ? (
          <SVG src={order.branding.svg} />
        ) : (
          <img
            alt=""
            src="bringly_horizontal_new_logo_colour.png"
            style={styles.bringlyLogo}
          />
        )}
      </Grid>
    </Grid>
  );
};

const styles = {
  root: {
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      paddingLeft: "24px",
    },
  },
  bringlyLogo: {
    maxWidth: "100px",
  },
  logo: {
    padding: "32px 0 32px",
    display: "flex",
    justifyContent: "center",
    "& svg": {
      maxWidth: "300px",
      maxHeight: "100px",
    },
    [theme.breakpoints.between(
      theme.breakpoints.values.mobile,
      theme.breakpoints.values.tablet
    )]: {
      padding: "32px 0 43px",
    },
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      padding: "25px 0 32px",
    },
  },
};

export default TrackingOrderHead;
