import React, { useContext } from "react";
import { OrderContext } from "../context";
import OrderTrackingTimeline from "./OrderTrackingTimeline";

export const getMultiColiItems = () => {
  const { referenceOrders } = useContext(OrderContext);
  return referenceOrders.map((order, index) => ({
    title: `Package ${index + 1} of ${referenceOrders.length}, ${
      order.deliveryStatus.name
    }`,
    getContent: () => (
      <OrderTrackingTimeline order={order} activeStep={order.activeStep} />
    ),
  }));
};
