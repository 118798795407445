export const GREY_COLOR = "grey";
export const RED_COLOR = "red";
export const ORANGE_COLOR = "orange";
export const CART = "track";

export const CREATED_STEP = {
  step: "CREATED_STEP",
};
export const UPDATED_BY_RECIPIENT_STEP = {
  step: "UPDATED_BY_RECIPIENT",
};
export const DELIVERED_STEP = {
  step: "DELIVERED_STEP",
};
export const NOT_DELIVERED_STEP = {
  step: "NOT_DELIVERED_STEP",
};
export const OUT_FOR_DELIVERY_STEP = {
  step: "OUT_FOR_DELIVERY_STEP",
};

export const PICKED_UP = {
  step: "PICKED_UP",
};
export const RETURNED_TO_PICKUP = {
  step: "RETURNED_TO_PICKUP",
};

export const NOT_SUCCESSFUL_STEP = {
  step: "NOT_SUCCESSFUL_STEP",
};

export const STANDARD_STEPS = [
  CREATED_STEP,
  PICKED_UP,
  OUT_FOR_DELIVERY_STEP,
  DELIVERED_STEP,
];
