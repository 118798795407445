import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { OrderContext } from "../context";
import theme from "../theme";

const formatHouseNumber = (houseNumber, houseAdditions) => {
  return houseAdditions ? `${houseNumber}-${houseAdditions}` : houseNumber;
};

const OrderInfo = () => {
  const { order } = useContext(OrderContext);

  return (
    <Grid container>
      <Grid item xs={6} style={{ marginBottom: theme.spacing(2) }}>
        <Typography variant="body1" sx={styles.subTitle}>
          Ontvanger
        </Typography>
        <Typography variant="body1" sx={styles.text}>
          {order.recipientName}
        </Typography>
        <Typography variant="body1" sx={styles.text}>
          {`${order.deliveryAddress.street} ${formatHouseNumber(
            order.deliveryAddress.houseNumber,
            order.deliveryAddress.houseAdditions
          )}`}
        </Typography>
        <Typography variant="body1" sx={styles.text}>
          {`${order.deliveryAddress.postcode} ${order.deliveryAddress.city}`}
        </Typography>
      </Grid>
      {order.branding && order.branding.showSender ? (
        <Grid item xs={6}>
          <Typography variant="body1" sx={styles.subTitle}>
            Afzender
          </Typography>
          <Typography variant="body1" sx={styles.text}>
            {order.retailerName}
          </Typography>
        </Grid>
      ) : undefined}
      <Grid item xs={6}>
        <Typography variant="body1" sx={{ ...styles.subTitle }}>
          Tracking code(s)
        </Typography>
        <Typography variant="body1" sx={styles.text}>
          {order.trackingCode}
        </Typography>
      </Grid>
    </Grid>
  );
};

const styles = {
  subTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
  },
  text: {
    lineHeight: "20px",
  },
};
export default OrderInfo;
