import React, { useContext, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "react-query";
import { getRequestUI, postRequestUI } from "common-utils/utils/api";
import { OrderContext } from "../context";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import theme from "../theme";
import Grid from "@mui/material/Grid";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import VerificationInput from "react-verification-input";
import MenuItem from "@mui/material/MenuItem";
import _ from "lodash";
import "../index.css";

const ChangeOrderDeliveryDate = () => {
  const { order, setOrder, setReferenceOrders } = useContext(OrderContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [deliveryDateOptions, setDeliveryDateOptions] = useState([]);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [verificationMethod, setVerificationMethod] = useState("email");

  useEffect(() => {
    if (order) {
      handleSetOrderDelivery(order);
    }
  }, [order]);

  const handleSetOrderDelivery = (order) => {
    setDeliveryDateOptions(order.dateOptions.map((option) => option.date));
    const currentDate = order.dateOptions.find((option) => {
      return option?.isCurrentDate;
    });
    if (currentDate && currentDate.date) {
      setSelectedDeliveryDate(currentDate.date);
    }
  };

  const handleSetDeliveryDateOption = (e) => {
    setSelectedDeliveryDate(e.target.value);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    clearData();
    setOpenDialog(false);
  };

  const handleCloseConfirmDialog = () => {
    clearData();
    setOpenConfirmDialog(false);
  };

  const handleResendTwoFactorCode = () => {
    setIsLoading(true);
    setErrorMessage("");
    resendTwoFactorCodeMutation.mutate();
  };

  const refreshOrderMutation = useMutation(refreshOrders, {
    onSuccess: (data) => {
      const { mainOrder, referencedOrders } = data;
      if (referencedOrders && referencedOrders.length) {
        setReferenceOrders(referencedOrders);
      }
      setOrder(mainOrder);
      setIsLoading(false);
      clearData();
      setOpenConfirmDialog(false);
    },
    onError: (error) => {
      setIsLoading(false);
    },
  });

  const resendTwoFactorCodeMutation = useMutation(resendTwoFactorCode, {
    onSuccess: () => {
      setIsLoading(false);
      setVerificationCode("");
      setErrorMessage("");
      setOpenDialog(false);
      setOpenConfirmDialog(true);
    },
    onError: (error) => {
      setIsLoading(false);
    },
  });

  const handleChangeDeliveryDate = () => {
    setIsLoading(true);
    setErrorMessage("");
    const data = {
      trackingCode: order.trackingCode,
      code: verificationCode,
      newDate: selectedDeliveryDate,
    };

    changeDateMutation.mutate(data);
  };

  async function changeDeliveryDate(data) {
    const result = await postRequestUI("/change-delivery-date", data);
    return result;
  }

  async function resendTwoFactorCode() {
    await postRequestUI("/resend-two-factor-code/date-change", {
      trackingCode: order.trackingCode,
      twoFactorMethod: verificationMethod,
    });
  }
  async function refreshOrders() {
    const result = await getRequestUI("/order/search", {
      trackingCode: order.trackingCode,
      zipcode: order.deliveryAddress.postcode,
    });
    return result;
  }

  const changeDateMutation = useMutation(changeDeliveryDate, {
    onSuccess: () => {
      refreshOrderMutation.mutate();
      setOpenConfirmDialog(false);
      setOpenSuccessDialog(true);
    },
    onError: (error) => {
      setIsLoading(false);
      setErrorMessage(error.message || "Failed to change delivery date");
    },
  });

  const clearData = () => {
    setErrorMessage("");
  };

  return (
    <>
      <Button style={styles.button} onClick={handleOpenDialog}>
        Bezorgdag
        <br /> wijzigen
      </Button>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Grid container justifyContent={"flex-end"}>
          <IconButton aria-label="close" onClick={handleCloseDialog}>
            <Close />
          </IconButton>
        </Grid>
        <Grid container alignItems={"center"} direction={"column"}>
          <DialogTitle>Bezorgdag wijzigen</DialogTitle>

          <DialogContentText>
            Wil je de bezorg dag veranderen? Dit kan tot je pakket ingepland is.
          </DialogContentText>
        </Grid>
        <DialogContent>
          <Box mt={2} />
          <Grid container spacing={2}>
            {deliveryDateOptions.length > 0 && (
              <Grid direction={"column"} padding={2} spacing={2} item xs={12}>
                <Grid item>Bezorgdatum</Grid>
                <Grid item>
                  <TextField
                    select
                    id="deliveryDate"
                    variant="outlined"
                    value={selectedDeliveryDate}
                    fullWidth
                    onChange={(e) => handleSetDeliveryDateOption(e)}
                  >
                    {deliveryDateOptions.map((option) => (
                      <MenuItem value={option} key={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Box mt={3} />
                <Grid item>
                  <Grid item>
                    Om de aanpassing te bevestigen, wordt er een verificatiecode
                    verstuurd
                  </Grid>
                  <Grid item>
                    <TextField
                      select
                      value={verificationMethod}
                      onChange={(e) => setVerificationMethod(e.target.value)}
                      fullWidth
                    >
                      <MenuItem value="email">Ontvang code via Mail</MenuItem>
                      {order.hasRecipientNumber && (
                        <MenuItem value="sms">Ontvang code via SMS</MenuItem>
                      )}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {errorMessage && (
              <Grid direction={"column"} padding={2} spacing={2} item xs={12}>
                <Grid item style={styles.errorMessage}>
                  {errorMessage}
                </Grid>
              </Grid>
            )}
          </Grid>
          <DialogActions>
            <Grid
              container
              alignItems={"center"}
              direction={"column"}
              spacing={2}
              paddingTop={2}
            >
              <Button
                onClick={handleResendTwoFactorCode}
                sx={styles.submitButton}
                disabled={errorMessage}
              >
                Verzenden
              </Button>
              <Link style={styles.link} onClick={handleCloseDialog}>
                Annuleren
              </Link>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Grid container justifyContent={"flex-end"}>
          <IconButton aria-label="close" onClick={handleCloseConfirmDialog}>
            <Close />
          </IconButton>
        </Grid>
        <DialogContent>
          <Grid container gap={2} justifyContent={"center"}>
            <Grid container alignItems={"center"}>
              <DialogContentText>
                {`Er is ${
                  verificationMethod === "sms" ? "sms" : "mail"
                } gestuurd met een verificatiecode. Voer deze code in om de aanpassing te bevestigen`}
              </DialogContentText>
            </Grid>

            <Grid
              container
              alignItems={"center"}
              direction={"column"}
              spacing={2}
              padding={2}
            >
              Verificatiecode:
              <VerificationInput
                value={verificationCode}
                length={4}
                validChars={"0-9"}
                autoFocus={true}
                classNames={{
                  container: "verifyContainer",
                  character: "verifyInput",
                  characterSelected: "verifyInputSelected",
                }}
                placeholder={""}
                onChange={(value) => {
                  setVerificationCode(value);
                }}
              />
            </Grid>
            <Grid
              container
              gap={2}
              justifyContent={"center"}
              alignContent={"center"}
            >
              {errorMessage && (
                <Grid direction={"column"} padding={2} spacing={2} item xs={12}>
                  <Grid item style={styles.errorMessage}>
                    {errorMessage}
                  </Grid>
                </Grid>
              )}
            </Grid>
            <DialogActions>
              <Grid
                container
                alignItems={"center"}
                direction={"column"}
                spacing={2}
                paddingTop={2}
              >
                <Button
                  sx={styles.submitButton}
                  onClick={handleChangeDeliveryDate}
                  disabled={verificationCode.length < 4}
                >
                  Verzenden
                </Button>
                <Link style={styles.link} onClick={handleCloseConfirmDialog}>
                  Annuleren
                </Link>
                <Link onClick={handleResendTwoFactorCode} style={styles.link}>
                  Verstuur verificatiecode opnieuw
                </Link>
              </Grid>
            </DialogActions>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSuccessDialog}
        onClose={() => setOpenSuccessDialog(false)}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
        fullWidth
      >
        <Grid container justifyContent="flex-end">
          <IconButton
            aria-label="close"
            onClick={() => setOpenSuccessDialog(false)}
          >
            <Close />
          </IconButton>
        </Grid>
        <DialogContent>
          <Grid
            container
            alignItems={"center"}
            direction={"column"}
            spacing={2}
            paddingBottom={2}
          >
            <DialogContentText
              id="success-dialog-description"
              style={{ textAlign: "center" }}
            >
              Uw aanpassing is succesvol gewijzigd.
            </DialogContentText>
          </Grid>
        </DialogContent>
      </Dialog>
      {isLoading && <CircularProgress sx={styles.spinner} />}
    </>
  );
};

const styles = {
  button: {
    lineHeight: "1.2",
    color: "black",
    backgroundColor: "white",
    textTransform: "none",
    width: "170px",
    height: "50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
    padding: "10px",
    "&:hover": {
      backgroundColor: "white",
    },
  },

  submitButton: {
    color: "white",
    backgroundColor: theme.palette.error.main,
    borderRadius: 0,
    paddingLeft: 2,
    paddingRight: 2,
    "&:hover": {
      backgroundColor: theme.palette.error.dark, // Darken on hover
    },
    "&.Mui-disabled": {
      backgroundColor: "#eaeaea",
      color: "#c0c0c0",
    },
  },
  link: {
    cursor: "ponter",
    color: "black",
  },
  spinner: {
    right: "50%",
    top: "50%",
    position: "absolute",
    zIndex: 9999,
  },
  verifyCodeContainer: {
    display: "flex",
    gap: "15px",
  },
  errorMessage: {
    color: theme.palette.error.main,
    textAlign: "center",
  },
};

export default ChangeOrderDeliveryDate;
