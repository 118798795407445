import cloneDeep from "lodash/cloneDeep";
import { CART } from "../constants/statuses";

export const formatSteps = (activeStep, STANDARD_STEPS) => {
  if (activeStep < 0) {
    return [];
  }
  return STANDARD_STEPS.slice(0, activeStep + 1);
};

export const extendStepsOnCart = (activeStep, STANDARD_STEPS) => {
  const formattedSteps = formatSteps(activeStep, STANDARD_STEPS);
  if (!formattedSteps.length) {
    return STANDARD_STEPS;
  }
  if (STANDARD_STEPS.length > formattedSteps.length) {
    const cloned = cloneDeep(STANDARD_STEPS);
    cloned.splice(formattedSteps.length, 0, CART);
    return cloned;
  }
  return STANDARD_STEPS;
};
