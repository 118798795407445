import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { OrderContext } from "../context";
import { getTimelineColor } from "../helper/colors";
import { useContext } from "react";

export const Connector = styled(StepConnector)(({ theme }) => {
  const { order } = useContext(OrderContext);
  const borderColor = getTimelineColor(order);

  return {
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 25,
      left: "calc(-50% + 10px)",
      right: "calc(50% + 10px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
      borderTopWidth: 6,
      borderRadius: 1,
    },
  };
});
