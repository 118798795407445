import {
  CREATED_STEP,
  DELIVERED_STEP,
  NOT_DELIVERED_STEP,
  NOT_SUCCESSFUL_STEP,
  OUT_FOR_DELIVERY_STEP,
  PICKED_UP,
  RETURNED_TO_PICKUP,
  UPDATED_BY_RECIPIENT_STEP,
} from "../constants/statuses";
import {
  IconRegistered,
  IconAtTheHub,
  IconDelivered,
  IconToPickUp,
  IconOnItsWay,
  IconReturnToPickUp,
  IconNotDelivered,
  IconClipboardPencil,
} from "@shared/components/lib/index";

export const matchingStep = (step) => {
  switch (step) {
    case CREATED_STEP.step: {
      return 0;
    }
    case UPDATED_BY_RECIPIENT_STEP.step: {
      return 0;
    }
    case PICKED_UP.step: {
      return 1;
    }
    case OUT_FOR_DELIVERY_STEP.step: {
      return 2;
    }
    case NOT_DELIVERED_STEP.step: {
      return 2;
    }
    case DELIVERED_STEP.step: {
      return 3;
    }
    case NOT_SUCCESSFUL_STEP.step: {
      return -1;
    }
    default: {
      return -1;
    }
  }
};

export const matchingStepOnIcon = (step, color) => {
  switch (step) {
    case CREATED_STEP.step: {
      return <IconRegistered color={color} />;
    }
    case UPDATED_BY_RECIPIENT_STEP.step: {
      return <IconClipboardPencil color={color} />;
    }
    case PICKED_UP.step: {
      return <IconAtTheHub color={color} />;
    }
    case RETURNED_TO_PICKUP.step: {
      return (
        <span>
          <IconReturnToPickUp color={color} />
        </span>
      );
    }
    case OUT_FOR_DELIVERY_STEP.step: {
      return <IconOnItsWay color={color} />;
    }
    case DELIVERED_STEP.step: {
      return <IconDelivered color={color} />;
    }
    case NOT_DELIVERED_STEP.step: {
      return (
        <span>
          <IconNotDelivered color={color} />
        </span>
      );
    }
    case NOT_SUCCESSFUL_STEP.step: {
      return <></>;
    }
    default: {
      return <></>;
    }
  }
};
