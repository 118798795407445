import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { OrderContext } from "../context";
import { formatTime, formatTimeETA } from "common-utils/utils/formatter";
import head from "lodash/head";
import theme from "../theme";

const EstimatedTime = () => {
  const { order } = useContext(OrderContext);

  if (!order.eta) {
    return <></>;
  }
  return (
    <Grid container justifyContent="end" sx={styles.root}>
      <Grid item md={6} sm={12}>
        <Grid container justifyContent="end">
          <Typography variant="body1" sx={styles.text}>
            {order.estimateText}
          </Typography>
          &nbsp;
          <Typography
            variant="body1"
            color={theme.palette.text.primary}
            sx={styles.text}
          >
            {getTime(order)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const getTime = (order) => {
  const latestStatus = head(order.statusHistory);
  if (!latestStatus) {
    return "";
  }
  if (order.isDelivered) {
    return formatTime(order.deliveryDate);
  }
  if (order.eta) {
    return formatTimeETA(order.eta);
  }
  return "";
};

const styles = {
  root: {
    marginTop: theme.spacing(3),
  },
  text: {
    fontWeight: 600,
  },
};
export default EstimatedTime;
