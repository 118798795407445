import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import theme from "../theme";

const MainAccordion = ({ items }) => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid container flexDirection="column" sx={styles.root}>
      {items.map((item) => (
        <Accordion
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography sx={styles.title}>{item.title}</Typography>
              <Typography sx={styles.id}>
                {expanded === item.id ? "Verberg" : "Details"}
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>{item.children}</AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
};

const styles = {
  root: {
    marginTop: "32px",
    borderTop: `2px solid ${theme.palette.primary.main}`,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    "& .MuiAccordion-root::before": {
      backgroundColor: theme.palette.primary.main,
      height: "2px",
    },
    "& .MuiAccordion-root.Mui-expanded::before": {
      opacity: 1,
    },
  },
  title: {
    fontSize: "20px",
    padding: "16px 0",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      fontSize: "15px",
      padding: "28px 0",
    },
  },
  id: {
    fontSize: "13px",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      fontSize: "11px",
    },
  },
};

export default MainAccordion;
