import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { useMutation } from "react-query";
import { postRequestUI } from "common-utils/utils/api";
import { OrderContext } from "../context";
import Link from "@mui/material/Link";
import { getTextColor } from "../helper/colors";
import theme from "../theme";

const ResendTwoFactorCode = () => {
  const { order, setError } = useContext(OrderContext);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setError("");
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const resendTwoFactorCodeMutation = useMutation(resendTwoFactorCode, {
    onSuccess: () => {
      setOpenDialog(false);
    },
    onError: (error) => {
      setError(error.message);
      setOpenDialog(false);
    },
  });

  const handleResendTwoFactorCode = () => {
    resendTwoFactorCodeMutation.mutate();
  };

  async function resendTwoFactorCode() {
    await postRequestUI("/resend-two-factor-code", {
      trackingCode: order.trackingCode,
    });
  }
  const color = getTextColor(order);
  return (
    <>
      <Link
        onClick={handleOpenDialog}
        sx={{
          ...styles.link,
          color,
          textDecoration: `underline ${color}`,
        }}
      >
        Verstuur verificatiecode opnieuw
      </Link>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Resend delivery code
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleResendTwoFactorCode} autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const styles = {
  link: {
    cursor: "pointer",
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      marginTop: theme.spacing(3),
      fontSize: "15px",
    },
  },
};

export default ResendTwoFactorCode;
