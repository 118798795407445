import React, { useContext } from "react";
import { DateFormatter } from "@shared/components/lib/index";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import theme from "../theme";
import { OrderContext } from "../context";
import { DeliveryTimeInterval } from "./DeliveryTimeInterval";
import ResendTwoFactorCode from "../components/ResendTwoFactorCode";
import { getBackgroundColor, getTextColor } from "../helper/colors";
import { StyleMuiGrid } from "./StyledMuiGrid";
import ChangeOrderDeliveryAddress from "./ChangeOrderDeliveryAddress";
import ChangeOrderDeliveryDate from "./ChangeOrderDeliveryDate";
import RedirectButton from "./RedirectButton";
import DeliveryDateComponent from "./DeliveryDateComponent";

const ExpectedDelivery = () => {
  const { order } = useContext(OrderContext);
  const backgroundColor = getBackgroundColor(order);
  const EtaDisplayOptions = order.eta ? ShowETA : HideETA;
  const StatusDisplayComponent = order.statusActionMessage
    ? UnHappyMessage
    : EtaDisplayOptions;

  return (
    <StyleMuiGrid
      sx={{ ...styles.root, backgroundColor }}
      container
      flexDirection="column"
      alignItems="start"
      justifyContent="center"
    >
      <StatusDisplayComponent />
      <ActionsBlock />
    </StyleMuiGrid>
  );
};

const UnHappyMessage = () => {
  const { order } = useContext(OrderContext);
  return (
    <>
      <Typography
        variant="h4"
        color={getTextColor(order)}
        sx={styles.statusActionMessage}
      >
        {order.statusActionMessage}
      </Typography>
    </>
  );
};

const ShowETA = () => {
  const { order } = useContext(OrderContext);
  const textColor = getTextColor(order);
  return (
    <>
      <Typography variant="caption" color={textColor} sx={styles.caption}>
        {order.statusMessage}
      </Typography>
      <Box sx={styles.deliveryDateBox}>
        <DeliveryDateComponent order={order} textColor={textColor} />
      </Box>
      <DeliveryTimeInterval />
    </>
  );
};

const HideETA = () => {
  const { order } = useContext(OrderContext);
  const textColor = getTextColor(order);
  return (
    <>
      <Typography variant="caption" color={textColor} sx={styles.caption}>
        {order.statusMessage}
      </Typography>
      <Box sx={styles.deliveryDateBox}>
        <Typography variant="caption" color={textColor} sx={styles.caption}>
          {order.etaText}
        </Typography>
      </Box>
    </>
  );
};

const ActionsBlock = () => {
  const { order } = useContext(OrderContext);
  return (
    <Grid container alignItems="center" style={styles.actionsBlock}>
      {order.changeAddress && (
        <Grid item style={{ marginRight: "30px" }}>
          <ChangeOrderDeliveryAddress />
        </Grid>
      )}
      {order.changeAddress && (
        <Grid item>
          <ChangeOrderDeliveryDate />
        </Grid>
      )}
      {order.trackingLink.mode === "overwrite" && (
        <Grid item>
          <RedirectButton />
        </Grid>
      )}
      {order.hasTwoFactorCode && (
        <Grid item>
          <ResendTwoFactorCode />
        </Grid>
      )}
    </Grid>
  );
};

const styles = {
  actionsBlock: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
  },
  root: {
    borderRadius: "0px 0px 20px 20px",
    padding: "40px 24px",
    minHeight: theme.spacing(33),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      padding: "32px 24px",
    },
  },
  caption: {
    lineHeight: theme.spacing(4),
    marginBottom: "24px",
    padding: "4px 0",
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      fontSize: "17px",
      marginBottom: "16px",
      padding: 0,
    },
  },
  statusActionMessage: {
    lineHeight: "36px",
    marginBottom: theme.spacing(3),
  },
  statusMessage: {
    lineHeight: "22px",
    marginBottom: theme.spacing(4),
  },
  deliveryDateBox: {
    marginBottom: "24px",
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      marginBottom: "16px",
    },
  },
};

export default ExpectedDelivery;
