import React from "react";
import { useContext } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import Grid from "@mui/material/Grid";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { NOT_SUCCESSFUL_STEP } from "../constants/statuses";
import { formatTime } from "common-utils/utils/formatter";
import theme from "../theme";
import { matchingStepOnIcon } from "../helper/statusChecking";
import sortBy from "lodash/sortBy";
import { getTimelineColor } from "../helper/colors";
import { OrderContext } from "../context";

const OrderTrackingTimeline = ({ order }) => {
  const sortedTimelineSteps = sortBy(order.timelineSteps, ["date"]);
  return (
    <Timeline>
      {sortedTimelineSteps.reverse().map((timelineStep, index, arr) => {
        const { order } = useContext(OrderContext);
        const backgroundColor = getTimelineColor(order);

        if (timelineStep.step === NOT_SUCCESSFUL_STEP.step) {
          return <></>;
        }
        const whiteColor = theme.palette.common.white;
        return (
          <TimelineItem key={timelineStep.id} sx={styles.item}>
            <TimelineSeparator sx={styles.separator}>
              <TimelineDot sx={{ backgroundColor, ...styles.dot }}>
                {matchingStepOnIcon(timelineStep.step, whiteColor)}
              </TimelineDot>
              {arr.length - 1 > index && (
                <TimelineConnector
                  sx={{ backgroundColor, ...styles.connector }}
                />
              )}
            </TimelineSeparator>
            <TimelineContent sx={styles.content}>
              <Grid container flexDirection="column">
                <Typography variant="body1" sx={styles.subTitle}>
                  {timelineStep.stepName}
                </Typography>
                <Typography variant="body1" sx={styles.text}>
                  {`${timelineStep.stepText ?? ""}`}
                </Typography>
              </Grid>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

const styles = {
  item: {
    minHeight: "100px",
    "&::before": {
      display: "none",
    },
  },
  subTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    paddingTop: "4px",
  },
  text: {
    lineHeight: "20px",
  },
  separator: {
    position: "relative",
  },
  connector: {
    position: "absolute",
    minHeight: "70px",
    bottom: "-14px",
    width: "6px",
  },
  dot: {
    zIndex: 1,
    "& svg": {
      height: theme.spacing(7),
      width: theme.spacing(7),
      [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
        height: theme.spacing(6),
        width: theme.spacing(6),
      },
    },
    "& span": {
      height: theme.spacing(7),
      width: theme.spacing(7),
      position: "relative",
      [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
        height: theme.spacing(6),
        width: theme.spacing(6),
        "& svg": {
          height: "24px",
          width: "24px",
        },
      },
      "& svg": {
        height: "26px",
        width: "26px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    },
  },
  content: {
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      paddingLeft: theme.spacing(4),
    },
  },
};

export default OrderTrackingTimeline;
