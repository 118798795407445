import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import Grid from "@mui/material/Grid";
import theme from "../theme";

const InternalAccordion = ({ items }) => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Grid container flexDirection="column" sx={styles.root}>
      {items.map((item) => (
        <Accordion
          expanded={expanded === item.title}
          onChange={handleChange(item.title)}
        >
          <AccordionSummary
            expandIcon={expanded === item.title ? <ClearIcon /> : <AddIcon />}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography sx={styles.title}>{item.title}</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>{item.getContent()}</AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
};

const styles = {
  root: {
    maxWidth: "514px",
    margin: "-8px -16px -16px",
    borderTop: `1px solid ${theme.palette.primary.main}`,
    "& .MuiAccordion-root::before": {
      backgroundColor: theme.palette.primary.main,
      height: "1px",
    },
    "& .MuiAccordion-root.Mui-expanded::before": {
      opacity: 1,
    },
  },
  title: {
    fontSize: "17px",
    fontWeight: 600,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      fontSize: "15px",
    },
  },
};

export default InternalAccordion;
